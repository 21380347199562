/* Use border-shadow */
.message-day {
  border-bottom: 1px #dddddd solid;
}

.message-container:hover {
  background-color:#f1eef1;
}

.profile-icon {
  border-radius: 4px;
  height: 36px;
  width: 36px;
}

.message-time {
  font-size: 12px;
}
