$primary: #3F0E40;
$secondary: #8C5888;
$dark: #350d36;
$light: #D3CBD2;
$success: #2BAC76;
$info: #1164A3;
$warning: #CD2553;
$white: #FFFFFF;
$muted: #616061;
$black: #1d1c1d;
$blue: #1164A3;
$green: #007a5a;

