.custom-search-input {
  border-left: none;
  border: none;
}

.custom-search-input-group, .custom-search-input-prepend, .custom-search-input {
  border-radius: .1rem;
}

.custom-search-input-prepend {
  background-color: #fff !important;
  color: #afb2b9;
  font-size: 1em;
}
