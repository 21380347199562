.border-shadow-bottom-grey {
  border-bottom: 1px solid #e2e2e2;
}

.channel-name {
  font-weight: bold;
}

.channel-topic {
  color: rgba(29, 28, 29, 0.7);
  font-size: 13px;
  line-height: 1.38463;
}
