@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Titillium+Web:wght@300;400;700&display=swap');


body {
  font-size: 15px !important;
}

.cursor-pointer {
  cursor: pointer;
}
