.center-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .status-code {
    border-right: solid 2px #000
  }
  .status-code h1 {
    font-size: 100px;
  }
}
