@import './app/themes/index.scss';
@import '~bootstrap/scss/bootstrap';

.subtext {
  font-size: 11px;
}

.border-shadow-light {
  border-bottom: 1px solid rgba(255,255,255,0.2);
  // -webkit-box-shadow: 0 1px 0 0 rgba(255,255,255,0.1);
  // -moz-box-shadow: 0 1px 0 0 rgba(255,255,255,0.1);
  // box-shadow: 0 1px 0 0 rgba(255,255,255,0.1);
}

.border-shadow-dark {
  border-bottom: 1px solid rgba(255,255,255,0.05);
}

.rotate180 {
  -webkit-transform: translate(180deg);
  -ms-transform: translate(180deg); // IE9 only
  transform: translate(180deg);
}

.rotate90 {
  -webkit-transform: translate(90deg);
  -ms-transform: translate(90deg); // IE9 only
  transform: translate(90deg);
}

.rotate270 {
  -webkit-transform: translate(270deg);
  -ms-transform: translate(270deg); // IE9 only
  transform: translate(270deg);
}

.rotate320 {
  -webkit-transform: translate(320deg);
  -ms-transform: translate(320deg); // IE9 only
  transform: translate(320deg);
}
