$memberWidth: 75px;
$amountWidth: 25px;

.item-container {
  max-height: 110px;
  text-overflow: ellipsis;
  &.selected {
    background-color: rgba(63,14,64, 1);
  }
  &:hover {
    background-color: rgba(63,14,64, 0.6);
  }
  .item-members {
    width: $memberWidth;
  }

  .item-members-amount {
    width: $amountWidth;
  }

  .item-description {
    width: calc(100% - #{$memberWidth} + #{$amountWidth});
    overflow-y: hidden;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn-select {
    font-size: 18px;
    padding: 2px 2px;
    width: 35px;
  }
}
