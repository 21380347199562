$header-height: 38px;
$sidebar-width: 240px;

.home-view {
  position: absolute;
  height: 100%;
  width: 100%;
}

.home-app-header {
  min-height: $header-height;
}

.home-app-container {
  height: calc(100% - #{$header-height});
  .active-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    grid-area: active-content;
    .channel-header {
      flex-shrink: 0;
      height: 52px;
    }
    .active-conversation-container {
      overflow-y: scroll;
    }
  }
}

.sidebar {
  width: $sidebar-width;
  overflow-y: auto;
  .wksp-info {
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (max-width: 576px) {
  .sidebar {
    width: 100%;
    overflow-y: auto;
  }
  .sidebar.sidebar-selected {
    display: none;
  }
}

.active-content {
  width: calc(100%-#{$sidebar-width});
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
