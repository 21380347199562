.channel-item div {
  cursor: pointer !important;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
}

/* ask about best way to handle classes */
/* use existing variable instead of hexa */
.menu-item :hover {
  background-color: #350d36;
}
