.text-editor-container  {
  height: 100%;
  .text-editor-input-container {
    overflow-y: auto;
    height: 60%;
    .DraftEditor-root {
      height: 40px;
    }
  }

  .text-editor-controls {
    height: 40%;
    background-color: #FAF7FA;
    .can-send {
      background-color: #007a5a;
      &:hover {
        opacity: .8;
      }
    }
  }
}
